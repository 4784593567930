import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

const linkStyle = {
  padding: "5px 10px",
  fontWeight: "bold",
  color: "black",
  textDecoration: "none",
}

const Header = ({ siteTitle }) => {
  const state = useMemo(() => Math.random(), [])
  const nonce = useMemo(() => Math.random(), [])

  const loginHref = `https://geohistory.auth0.com/authorize?response_type=id_token token&client_id=0kI352xozToK25F3pqCpsRpklvUlm85t&redirect_uri=https://www.geohistory.net?authorize&state=${state}&nonce=${nonce}&scope=openid%20name%20picture&`

  return (
    <header
      style={{
        borderBottom: "2px solid #616161",
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          padding: `1rem`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `goldenrod`,
              textDecoration: `none`,
              fontFamily: "'Open Sans', sans-serif",
              fontWeight: 300,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <div>
          <a style={linkStyle} href={loginHref}>
            Account
          </a>
          |
          <a style={linkStyle} href="mailto:ws20johnhu@woodsideschool.us">
            Contact Creator
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
